import { axios } from './apiAssets'

export default {
  getUsers() {
    return axios.get(`/users`)
  },

  getCurrentUser() {
    return axios.get(`api/v1/users/~`)
  },

  login(email: string, password: string ) {
    return axios.post(`/auth/login`,  {
      email, password
    });
  },

  register(email: string, password: string) {
    return axios.post(`/auth/register`,  {
      email, password
    });
  },
};
