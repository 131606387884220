// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Circles }  from 'react-loader-spinner';
import styles from './Loader.module.scss'

export default function Loader() {
  return (
    <div className={styles.wrp}>
      <Circles color="#3f51b5" height={80} width={80} />
    </div>
  );
};


