import axios from 'axios';

const domain = `q.welbex-core.ru`
axios.defaults.baseURL = `https://${domain}`;
axios.defaults.withCredentials = true
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export { axios, domain }

