import { axios } from './apiAssets'
import { IQueueNewSettings } from '../assets/interfaces/IQueueSettings'

export default {
  getAllSettings() {
    return axios.get(`api/v1/settings`)
  },

  postQueueSettings(settings: IQueueNewSettings) {
    return axios.post(`api/v1/settings`, settings)
  },

  patchQueueSettings(id: number, settings: IQueueNewSettings) {
    return axios.put(`api/v1/settings/${id}`, settings)
  },

  deleteQueue(id: number) {
    return axios.delete(`api/v1/settings/${id}`)
  },

  getQueueLogs(id: number) {
    return axios.get(`api/v1/queue/${id}?with=waiting,active,delayed,failed,paused,completed&limit=5`)
  },
};