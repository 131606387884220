import { TState, TDispatch } from '../store'

export default {
  getIsLoading: (state: TState) => state.auth.isLoading,
  getToken: (state: TState) => state.auth.token,
  getIsAuthenticated: (state: TState) => state.auth.isAuthenticated,
  getUserId: (state: TState) => state.auth.userId,
  getError: (state: TState) => state.auth.error,
}

