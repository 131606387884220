import { createSlice } from '@reduxjs/toolkit';
import { IQueueSettings } from '../../assets/interfaces/IQueueSettings'
import { IQueueLogs } from '../../assets/interfaces/IQueueLogs'

interface IQueuesState {
  queues: [] | IQueueSettings[],
  queueLogs: null | IQueueLogs,
  isLoading: boolean,
  isLogsLoading: boolean,
  error: null | string;
}

const initialQueuesState: IQueuesState = {
  queues: [],
  queueLogs: null,
  isLoading: false,
  isLogsLoading: false,
  error: null
};

const { actions: queuesActions, reducer: queuesReducer } = createSlice({
  name: 'queues',
  initialState: initialQueuesState,
  reducers: {
    getAllSuccess: (state, { payload }) => {
      state.queues = payload;
      state.isLoading = false;
      state.error = null;
    },
    getAllRequest: state => {
      state.isLoading = true;
    },
    getAllError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    postQueueSuccess: (state, { payload }) => {
      state.queues = [...state.queues, payload];
      state.isLoading = false;
      state.error = null;
    },
    postQueueRequest: state => {
      state.isLoading = true;
    },
    postQueueError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    patchQueueSuccess: (state, { payload }) => {
      state.queues = state.queues.map(queue => queue.id === payload.queueId ? { ...queue, ...payload.data } : queue );
      state.isLoading = false;
      state.error = null;
    },
    patchQueueRequest: state => {
      state.isLoading = true;
    },
    patchQueueError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    deleteQueueSuccess: (state, { payload }) => {
      state.queues = state.queues.filter(({ id }) => Number(id) !== Number(payload) );
      state.isLoading = false;
      state.error = null;
    },
    deleteQueueRequest: state => {
      state.isLoading = true;
    },
    deleteQueueError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },

    /* --- */ 
    getQueueLogsSuccess: (state, { payload }) => {
      state.queueLogs = payload
      state.isLogsLoading = false;
      state.error = null;
    },
    getQueueLogsRequest: state => {
      state.isLogsLoading = true;
    },
    getQueueLogsError: (state, { payload }) => {
      state.error = payload;
      state.isLogsLoading = false;
    },
  },
});

export { queuesActions, queuesReducer };
