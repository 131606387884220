import { createSlice } from '@reduxjs/toolkit';

interface IAuthState {
  isAuthenticated: boolean,
  token: null | string,
  userId: number,
  isLoading: boolean,
  error: null | any
}

const initialAuthState: IAuthState = {
  isAuthenticated: false,
  token: null,
  userId: 0,
  isLoading: false,
  error: null
};

const { actions: authActions, reducer: authReducer  } = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    getCurrentUserSuccess: (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
    },
    getCurrentUserRequest: state => {
      state.isLoading = true;
    },
    getCurrentUserError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    loginSuccess: (state, { payload }) => {
      state.token = payload
      state.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
    },
    loginRequest: state => {
      state.isLoading = true;
    },
    loginError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    registerSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    registerRequest: state => {
      state.isLoading = true;
    },
    registerError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    /* --- */ 
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.userId = 0;
      state.isLoading = false;
      state.error = null;
    },
    logoutRequest: state => {
      state.isLoading = true;
    },
    logoutError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export { authActions, authReducer };
