import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queuesApi from '../../api/queuesApi'
import { TDispatch } from '../store' 
import { queuesActions } from './queues-reducer';
import { IQueueNewSettings } from '../../assets/interfaces/IQueueSettings'
  
export default {
  getAll: (userId: number) => async (dispatch: TDispatch) => {
    dispatch(queuesActions.getAllRequest());
    try {
      const res = await queuesApi.getAllSettings();
      dispatch(queuesActions.getAllSuccess(res.data.settings || []));
    } catch (err: any) {
      toast.error('Ошибка получения списка очередей! ')
      dispatch(queuesActions.getAllError(err?.response?.data || err));
    }
  },

  postSettings: (data: IQueueNewSettings) => async (dispatch: TDispatch) => {
    dispatch(queuesActions.postQueueRequest());
    try {
      const res = await queuesApi.postQueueSettings(data);
      dispatch(queuesActions.postQueueSuccess(res.data.data));
      toast.success('Настройки сохранены')
    } catch (err: any) {
      toast.error('Ошибка сохранения настроек очереди! ' )
      dispatch(queuesActions.postQueueError(err?.response?.data || err));
    }
  },

  patchSettings: (queueId: number, data: IQueueNewSettings) => async (dispatch: TDispatch) => {
    dispatch(queuesActions.patchQueueRequest());
    try {
      const res = await queuesApi.patchQueueSettings(queueId, data);
      dispatch(queuesActions.patchQueueSuccess({ queueId, data }));
      toast.success('Изменения сохранены')
    } catch (err: any) {
      toast.error('Ошибка редактирования настроек очереди! ')
      dispatch(queuesActions.patchQueueError(err?.response?.data || err));
    }
  },

  deleteSettings: (id: number) => async (dispatch: TDispatch) => {
    dispatch(queuesActions.deleteQueueRequest());
    try {
      const res = await queuesApi.deleteQueue(id);
      dispatch(queuesActions.deleteQueueSuccess(id));
      toast.success('Очередь удалена')
    } catch (err: any) {
      toast.error('Ошибка удаления очереди! ')
      dispatch(queuesActions.deleteQueueError(err?.response?.data || err));
    }
  },

  getQueueLogs: (id: number) => async (dispatch: TDispatch) => {
    dispatch(queuesActions.getQueueLogsRequest());
    try {
      const res = await queuesApi.getQueueLogs(id);
      dispatch(queuesActions.getQueueLogsSuccess(res.data));
    } catch (err: any) {
      toast.error('Ошибка получения логов очереди! ')
      dispatch(queuesActions.getQueueLogsError(err?.response?.data || err));
    }
  },

}

