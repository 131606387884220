import { Switch, Route} from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import { routes, paths } from './routes/routes'
import { authSelectors, authOperations} from './redux/auth'
import { queuesOperations } from './redux/queues'
import RoutePrivate from './components/RoutePrivate'
import RoutePublic from './components/RoutePublic'
import Loader from './components/Loader'

const Page404 = lazy(() => import('./pages/Page404' /*webpackChunkName: "Page404" */));

export default function App() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)
  const userId = useSelector(authSelectors.getUserId)
  const authError = useSelector(authSelectors.getError)
  
  useEffect(() => {
    dispatch(authOperations.getCurrentUser())
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(queuesOperations.getAll(userId))
    }
  }, [isAuthenticated])

   useEffect(() => {
    if (!isAuthenticated && authError) {
      history.push({ pathname: paths.loginPage })
    } else {
      history.push({ pathname: location.pathname ===  paths.loginPage ? paths.queuesPage : location.pathname,  search: location.search })
    }
  }, [isAuthenticated, authError])

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(
            ({ path, isProtected, redirectTo, exact, component: Component }) =>
               isProtected ? (
                <RoutePrivate
                  exact={exact}
                  key={path}
                  path={path}
                  redirectTo={redirectTo}
                >
                  <Component />
                </RoutePrivate>
              ) : (
                <RoutePublic
                  exact={exact}
                  key={path}
                  path={path}
                  redirectTo={redirectTo}
                  >
                 <Component />
                </RoutePublic>
              ),
          )}
          <Route
          component={Page404}
        />
        </Switch>
      </Suspense>
      
      <ToastContainer
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
