import { lazy } from 'react';

const LoginPage = lazy(() => import('../pages/Login' /*webpackChunkName: "LoginPage" */));
const RegisterPage = lazy(() => import('../pages/Register' /*webpackChunkName: "RegisterPage" */));
const QueuesPage = lazy(() => import('../pages/Queues' /*webpackChunkName: "QueuesPage" */));
const QueuePage = lazy(() => import('../pages/Queue' /*webpackChunkName: "QueuePage" */));

const paths = {
  loginPage: '/',
  registerPage: '/register',
  queuesPage: '/queues',
  queuePage: '/queues/:queueId',
  exactQueuePage: (id: number) => `/queues/${id}`,

}

const routes = [
  { 
    path: paths.loginPage,
    exact: true,
    isProtected: false,
    redirectTo: paths.queuesPage,
    component: LoginPage,
  },
  { 
    path: paths.registerPage,
    exact: true,
    isProtected: false,
    redirectTo: paths.queuesPage,
    component: RegisterPage,
  },
  {
    exact: true,
    isProtected: true,
    redirectTo:  paths.loginPage,
    path: paths.queuesPage,
    component: QueuesPage,
  },
  {
    exact: true,
    isProtected: true,
    redirectTo:  paths.loginPage,
    path: paths.queuePage,
    component: QueuePage,
  },
]

export { paths, routes };

