import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authApi from '../../api/authApi'
import { TState, TDispatch } from '../store' 
import { authActions } from './auth-reducer';

const tokenServices = {
  set(token: string) {
    axios.defaults.headers.common['Authorization'] = `Basic ${token}`
  },
  unset() {
    axios.defaults.headers.common['Authorization'] = ''
  },
};

export default {
  getCurrentUser: () => async (dispatch: TDispatch, getState: any) => {
    const {
      auth: { token: persistedToken },
    } = getState();
    if (!persistedToken) return;

    tokenServices.set(persistedToken)
    dispatch(authActions.getCurrentUserRequest());
    try {
      await authApi.getCurrentUser();
      dispatch(authActions.getCurrentUserSuccess());
    } catch (err: any) {
      dispatch(authActions.getCurrentUserError( err?.response?.data || err));
    }
  },
  register: (login: string, password: string ) => async (dispatch: TDispatch) => {
    dispatch(authActions.registerRequest());
    try {
      await authApi.register(login, password);
      dispatch(authActions.registerSuccess());
      toast.success('Успешная регистрация')
      const { data } = await authApi.login(login, password);
      const token = data.access_token
      tokenServices.set(token)
      dispatch(authActions.loginSuccess(token));
    } catch (err: any) {
      const errMess = err?.response?.data?.message || ''
      toast.error('Ошибка регистрации! ' + errMess)
      dispatch(authActions.registerError( errMess || err));
    }
  },
  login: (login: string, password: string ) => async (dispatch: TDispatch) => {
    dispatch(authActions.loginRequest());
    try {
      const { data } = await authApi.login(login, password);
      const token = data.access_token
      tokenServices.set(token)
      dispatch(authActions.loginSuccess(token));
    } catch (err: any) {
      toast.error('Ошибка авторизации! ')
      dispatch(authActions.loginError( err?.response?.data || err));
    }
  },
  logout: () => async (dispatch: TDispatch) => {
    dispatch(authActions.logoutRequest());
    try {
      dispatch(authActions.logoutSuccess());
      tokenServices.unset()
    } catch (err: any) {
      toast.error('Ошибка деавторизации! ')
      dispatch(authActions.logoutError( err?.response?.data || err));
    }
  },
}

